import React from 'react';
import ReactDOM from 'react-dom';
import CookieNotice from 'vivino-js/components/CookieNotice';
import Page from 'vivino-js/utilities/Page';

function render() {
  const cookieNoticeElement = document.getElementById('cookie-notice-container');

  if (!cookieNoticeElement) {
    return null;
  }

  ReactDOM.render(
    <Page>
      <CookieNotice />
    </Page>,
    cookieNoticeElement
  );
}

render();

export { render };
