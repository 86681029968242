import { Button, ComponentSize } from '@vivino/js-react-common-ui';
import React, { useState } from 'react';
import { createPrivacyConsent } from 'vivino-js/api/privacy';
import t from 'vivino-js/translationString';

import styles from './cookieNotice.scss';

const TRANSLATIONS = {
  privacyPolicy: 'common.privacy_policy',
  weUseCookies:
    'common.we_use_cookies_to_improvate_your_online_experience_by_using_the_site_you_concent_to_this_use',
  ok: 'common.ok',
};

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await createPrivacyConsent();
    setIsVisible(false);
    setIsLoading(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.cookieNotice}>
      <div className={styles.content}>
        <span>{t(TRANSLATIONS.weUseCookies)}</span>{' '}
        <a href="/privacy" target="_blank" className={styles.link}>
          {t(TRANSLATIONS.privacyPolicy)}
        </a>
        .
      </div>

      <Button onClick={handleClick} working={isLoading} size={ComponentSize.Large} fullWidth>
        {t(TRANSLATIONS.ok)}
      </Button>
    </div>
  );
};

export default CookieNotice;
