import { Navigation } from '@vivino/js-web-common';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  CART_ITEM_SOURCE_TYPES,
  CartItemSourceContext,
} from 'vivino-js/context/CartItemSourceContext';
import { CartsProvider } from 'vivino-js/context/CartsContext';
import Page from 'vivino-js/utilities/Page';

function render() {
  const navigation = document.getElementById('navigation-container');
  const countryCode = window.__PRELOADED_COUNTRY_CODE__;
  const trackingScreenName = window.__PRELOADED_STATE__.trackingScreenName

  navigation &&
    ReactDOM.render(
      <Page latest>
        <CartsProvider>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.EXPLORE_NAV}>
            <Navigation
              countryCode={countryCode}
              stateCode={window.__PRELOADED_STATE_CODE__}
              initialUserNotification={window.__PRELOADED_USER_NOTIFICATION__}
              isGhosting={window.__PRELOADED_STATE__?.isGhosting || null}
              cellarLinks={window.__PRELOADED_CELLAR_LINKS__ || []}
              trackingScreenName={trackingScreenName}
            />
          </CartItemSourceContext.Provider>
        </CartsProvider>
      </Page>,
      navigation
    );
}

render();

export { render };
